import React, { useEffect, useState } from 'react'
import { cloneDeep, difference, isEmpty } from 'lodash'

import CheckboxTabLabelled from '../../BaseComponents/Checkboxes/CheckboxTabLabelled'
import TextInputOutlined from '../../BaseComponents/Text/TextInputOutlined'
import PickerContained from '../../BaseComponents/Pickers/PickerContained'
import { IDataFieldDetails } from '../../../config/dataFieldConfig'
import { ColorPalette } from '../../../config/colors'
import TwoColumnSelector from '../TwoColumnSelector/TwoColumnSelector'
import { AutoPopulateFields } from './AutoPopulateFields'
import { IFieldConfigItem } from '../../../interfaces'
import { ConditionalSelectorItems } from '../../../types'
import { ConfigSectionHeader } from '../ConfigSectionHeader'
import { RadioGroupContained } from '../../BaseComponents/RadioGroup/RadioGroupContained'

interface IDataFieldDetailsProps {
  selectedAssociation?: string
  fieldParameters: IDataFieldDetails
  existingSelectorTypeFields?: IFieldConfigItem[]
  fieldConfig: Record<string, any>
  style?: React.CSSProperties
  updateFieldDetail: (
    detailName: keyof IDataFieldDetails,
    newValue: string | string[] | number | boolean | {},
    callback?: () => void,
  ) => void
  openSelectorModal: () => void
  openConditionalSelectorModal?: (
    conditionalSelectorRelatedFieldValue: string,
    conditionalSelectorItems: ConditionalSelectorItems,
  ) => void
}

const DataFieldDetails = (props: IDataFieldDetailsProps) => {
  const [conditionalSelectorRelatedField, setConditionalSelectorRelatedField] = useState('')
  const [conditionalSelectorRelatedFieldValue, setConditionalSelectorRelatedFieldValue] = useState('')
  const [shouldAutoPopulate, setShouldAutoPopulate] = useState(false)

  useEffect(() => {
    if (props.fieldParameters.autoPopulate && !isEmpty(props.fieldParameters.autoPopulate)) {
      setShouldAutoPopulate(true)
    }
  }, [props.fieldParameters.autoPopulate])

  useEffect(() => {
    const conditionalSelectorItems = props.fieldParameters.conditionalSelectorItems
    if (conditionalSelectorItems) {
      const relatedFieldLabel = getFieldLabelFromKey(conditionalSelectorItems.priFieldKey)
      if (relatedFieldLabel) {
        setConditionalSelectorRelatedField(relatedFieldLabel)
        if (!conditionalSelectorRelatedFieldValue) {
          setConditionalSelectorRelatedFieldValue(Object.keys(conditionalSelectorItems.values)[0])
        }
      }
    }
  }, [])

  const {
    selectedAssociation,
    fieldParameters: {
      key: fieldName,
      compType,
      selectorItems,
      compulsory,
      allowCustomOption,
      dateFormat,
      multiLine,
      suggestionsEnabled,
      useDefaultValue,
      defaultValue,
      setDefaultValueFromHistory,
      disabled,
      maxItems,
      conditionalSelectorItems,
      autoPopulate,
      singleSelectionMode,
    },
    existingSelectorTypeFields,
    fieldConfig,
    updateFieldDetail,
    openSelectorModal,
    openConditionalSelectorModal,
  } = props

  // TODO: DataFieldsSideMenu component also has this logic. Refactor to a common place
  let fieldConfigObj = { ...fieldConfig }

  fieldConfigObj = Object.keys(fieldConfigObj)
    .sort((a, b) => {
      if (!fieldConfigObj[a].label) return -1
      if (!fieldConfigObj[b].label) return 1
      return fieldConfigObj[a].label.localeCompare(fieldConfigObj[b].label)
    })
    .reduce((acc, key) => {
      // @ts-ignore
      acc[key] = fieldConfigObj[key]
      return acc
    }, {})

  const setAutoPopulateByValues = (fieldValues: string[]) => {
    const existingAutoPopulateItemKeys = autoPopulate ? Object.keys(autoPopulate) : []
    const newAutoPopulateItemKeys = difference(fieldValues, existingAutoPopulateItemKeys)
    newAutoPopulateItemKeys.forEach((fieldValue) => {
      const autoPopulateKey = fieldValue
      setAutoPopulateFieldValue(autoPopulateKey)
    })
  }

  const setAutoPopulateFieldValue = (item: string, field?: string, value?: string, previousField?: string) => {
    const existingItemValues = autoPopulate && autoPopulate[item] ? cloneDeep(autoPopulate[item]) : {}

    if (previousField && field && value) {
      if (field === '') {
        // field selection removed, remove the field of the autoPopulate object
        delete existingItemValues[previousField]
        updateFieldDetail('autoPopulate', {
          ...autoPopulate,
          [item]: {
            ...existingItemValues,
          },
        })
        return
      }
      if (field !== previousField) {
        // if existing field selection is changed, replace the previous field of the autoPopulate object
        let newItemValues = {}
        Object.keys(existingItemValues).forEach((key) => {
          if (key === previousField) {
            newItemValues = {
              ...newItemValues,
              [field]: '',
            }
          } else {
            newItemValues = {
              ...newItemValues,
              [key]: existingItemValues[key],
            }
          }
        })
        updateFieldDetail('autoPopulate', {
          ...autoPopulate,
          [item]: {
            ...newItemValues,
          },
        })
        return
      }
    }
    const existingAutoPopulate = autoPopulate ? cloneDeep(autoPopulate) : {}
    const autoPopulateUpdate =
      field && value !== undefined
        ? {
            ...existingAutoPopulate,
            [item]: {
              ...existingItemValues,
              [field]: value,
            },
          }
        : { ...existingAutoPopulate, [item]: {} }
    updateFieldDetail('autoPopulate', autoPopulateUpdate)
  }

  const removeAutoPopulateByFieldValue = (fieldValue: string) => {
    const autoPopulateUpdate = autoPopulate ? cloneDeep(autoPopulate) : {}
    if (autoPopulateUpdate.hasOwnProperty(fieldValue)) {
      delete autoPopulateUpdate[fieldValue]
      updateFieldDetail('autoPopulate', autoPopulateUpdate)
    }
  }

  // TODO: use config.utils.ts version instead..?
  const getFieldKeyFromLabel = (label: string) => {
    let fieldObj = existingSelectorTypeFields?.find((field) => field.label === label)
    if (fieldObj) {
      return fieldObj.key
    }
    return ''
  } // TODO: use config.utils.ts version instead..?
  const getFieldLabelFromKey = (key: string) => {
    let fieldObj = existingSelectorTypeFields?.find((field) => field.key === key)
    if (fieldObj) {
      return fieldObj.label
    }
    return ''
  }

  const getTranslatedCompType = (compType: string, dateFormat?: string) => {
    if (compType === 'conditionalSelector') {
      return 'selector (conditional)'
    }
    if (compType === 'itemSelector') {
      return 'item selector'
    }
    if (compType === 'text' && dateFormat && dateFormat.length) {
      return 'date'
    }
    return compType
  }

  const handleCompTypeChange = (compType: string) => {
    if (compType === 'date') {
      const defaultDateFormat = 'YYYY/MM/DD'
      updateFieldDetail('compType', 'text', () => updateFieldDetail('dateFormat', defaultDateFormat))
      return
    }
    const clearDateFormat = () => updateFieldDetail('dateFormat', '')
    if (compType === 'selector (conditional)') {
      updateFieldDetail('compType', 'conditionalSelector', clearDateFormat)
      return
    }
    if (compType === 'item selector') {
      updateFieldDetail('compType', 'itemSelector', clearDateFormat)
      return
    }

    updateFieldDetail('compType', compType, clearDateFormat)
  }

  const handleOptionClick = () => {
    const relatedFieldKey = getFieldKeyFromLabel(conditionalSelectorRelatedField)
    if (conditionalSelectorRelatedFieldValue && openConditionalSelectorModal) {
      let values: Record<string, string[]> = {}
      if (conditionalSelectorItems) {
        values[conditionalSelectorRelatedFieldValue] =
          conditionalSelectorItems.values[conditionalSelectorRelatedFieldValue]
      }
      openConditionalSelectorModal(conditionalSelectorRelatedFieldValue, {
        priFieldKey: relatedFieldKey,
        values,
      })
    }
  }

  const handleSelection = (values: string[], isMount: boolean, fieldName: string) => {
    if (!isMount) {
      updateFieldDetail(fieldName as keyof IDataFieldDetails, values[0] === 'YES')
    }
  }

  const handleShouldAutoPopulateChange = (values: string[], isMount: boolean) => {
    if (!isMount) {
      setShouldAutoPopulate(values[0] === 'YES')
    }
  }

  const handleAutoPopulateValuePairChange = (item: string, field?: string, value?: string, previousField?: string) =>
    setAutoPopulateFieldValue(item, field, value, previousField)

  const handleRelatedFieldChange = (e: any) => {
    setConditionalSelectorRelatedField(e.target.value)
    setConditionalSelectorRelatedFieldValue('')
  }

  const getConditionalSelectorCompTypeInputs = () => {
    function generateRelatedFieldSelectorItems() {
      let relatedFieldSelectorItems: string[] = []
      relatedFieldSelectorItems = [
        '',
        ...(fieldConfig[getFieldKeyFromLabel(conditionalSelectorRelatedField)]?.selectorItems || []),
      ]
      return relatedFieldSelectorItems
    }

    function generateOptions() {
      if (
        conditionalSelectorItems &&
        conditionalSelectorRelatedFieldValue &&
        conditionalSelectorItems.values[conditionalSelectorRelatedFieldValue]
      ) {
        return conditionalSelectorItems.values[conditionalSelectorRelatedFieldValue]
      }
      return []
    }

    let relatedFieldSelectorItems: string[] = []
    if (conditionalSelectorRelatedField) {
      relatedFieldSelectorItems = generateRelatedFieldSelectorItems()
    }

    let options: string[] = generateOptions()
    return (
      <div>
        <div style={styles.sectionInput}>
          <PickerContained
            style={{ width: '100%', padding: '10px 37px 15px' }}
            label="DEPENDS ON WHICH SELECTOR FIELD?"
            items={existingSelectorTypeFields?.map((field) => field.label).sort() || []}
            value={conditionalSelectorRelatedField}
            onChange={handleRelatedFieldChange}
          />
        </div>
        <div style={styles.sectionInput}>
          <RadioGroupContained
            label={'CHOOSE THE RELATED FIELD VALUE'}
            value={conditionalSelectorRelatedFieldValue}
            options={relatedFieldSelectorItems.map((item) => ({
              key: item,
              label: item,
            }))}
            rowDisplay={true}
            disabled={!conditionalSelectorRelatedField}
            onChange={(e) => setConditionalSelectorRelatedFieldValue(e.target.value)}
            optionsStyle={{ justifyContent: 'space-around', gap: 20 }}
            style={{ padding: '10px 37px 15px' }}
          />
        </div>
        <TwoColumnSelector
          label="THE FOLLOWING OPTIONS WILL BE PRESENTED"
          values={options}
          onClick={handleOptionClick}
        />
      </div>
    )
  }

  const translatedCompType = getTranslatedCompType(compType, dateFormat)
  const thusanyoJobType = fieldName === 'jobType' && selectedAssociation === 'thusanyo'
  const componentTypeOptions = thusanyoJobType
    ? ['text']
    : ['', 'date', 'item selector', 'picker', 'selector', 'selector (conditional)', 'text']

  let conditionalSelectorCompTypeInputs = null
  if (translatedCompType === 'selector (conditional)') {
    conditionalSelectorCompTypeInputs = getConditionalSelectorCompTypeInputs()
  }

  let selectorOrPickerCompTypeInputs = null
  if (['selector', 'picker', 'itemSelector'].indexOf(compType) > -1) {
    let defaultValueComponent = null
    if (useDefaultValue) {
      defaultValueComponent = (
        <div style={styles.sectionInput}>
          <PickerContained
            style={{
              width: '100%',
              padding: '1em 2.5em',
            }}
            value={defaultValue || ''}
            label={'Default value'}
            items={selectorItems || []}
            onChange={(e) => updateFieldDetail('defaultValue', e.target.value)}
          />
        </div>
      )
    }

    let autoPopulateFieldsComponent = null
    if (selectorItems && selectorItems.length > 0) {
      autoPopulateFieldsComponent = (
        <AutoPopulateFields
          autoPopulate={autoPopulate || {}}
          shouldAutoPopulate={shouldAutoPopulate}
          selectorItems={selectorItems}
          fieldConfig={fieldConfig}
          onShouldAutoPopulateChange={handleShouldAutoPopulateChange}
          onFieldValuePairChange={handleAutoPopulateValuePairChange}
        />
      )
    }

    selectorOrPickerCompTypeInputs = (
      <>
        <TwoColumnSelector
          label="THE FOLLOWING OPTIONS WILL BE PRESENTED TO THE USER"
          values={selectorItems || []}
          onClick={openSelectorModal}
        />
        <CheckboxTabLabelled
          style={styles.sectionInput}
          itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
          label={'HAS DEFAULT VALUE?'}
          values={useDefaultValue ? ['yes'] : ['no']}
          selectorItems={['no', 'yes']}
          selectionHandler={(values: string[], isMount: boolean) => handleSelection(values, isMount, 'useDefaultValue')}
        />
        {defaultValueComponent}
        <CheckboxTabLabelled
          style={styles.sectionInput}
          itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
          label={'SET DEFAULT FROM HISTORY?'}
          values={setDefaultValueFromHistory ? ['yes'] : ['no']}
          selectorItems={['no', 'yes']}
          selectionHandler={(values: string[], isMount: boolean) =>
            handleSelection(values, isMount, 'setDefaultValueFromHistory')
          }
        />
        {autoPopulateFieldsComponent}
      </>
    )
  }

  let selectorCompTypeInputs = null
  if (translatedCompType === 'selector') {
    selectorCompTypeInputs = (
      <>
        <CheckboxTabLabelled
          style={styles.sectionInput}
          itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
          label={'SHOULD USERS TO ABLE TO ADD THEIR OWN OPTION TO BE SELECTED?'}
          values={allowCustomOption ? ['yes'] : ['no']}
          selectorItems={['no', 'yes']}
          selectionHandler={(values: string[], isMount) => {
            if (!isMount) {
              updateFieldDetail('allowCustomOption', values[0] === 'YES')
            }
          }}
        />
        <TextInputOutlined
          style={styles.sectionInput}
          label="MAX SELECTABLE ITEMS"
          placeholder="Specify a number (default is 1)"
          value={maxItems ? maxItems.toString() : ''}
          onClick={() => null}
          textHandler={(e) => {
            let value = e.target.value
            // only allow numbers as input
            if (value.match(/^[0-9]*$/)) {
              updateFieldDetail('maxItems', +value)
            }
          }}
          disabled={false}
        />
      </>
    )
  }

  let dateCompTypeInputs = null
  if (translatedCompType === 'date') {
    dateCompTypeInputs = (
      <>
        <TextInputOutlined
          style={styles.sectionInput}
          label="DATE FORMAT"
          placeholder="Date format (e.g. YYYY/MM/DD)"
          value={dateFormat || ''}
          onClick={() => null}
          textHandler={(e) => {
            let value = e.target.value
            updateFieldDetail('dateFormat', value)
          }}
          disabled={true}
        />
        <CheckboxTabLabelled
          style={styles.sectionInput}
          itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
          label={'SHOW AS DISABLED?'}
          values={disabled ? ['yes'] : ['no']}
          selectorItems={['no', 'yes']}
          selectionHandler={(values: string[], isMount) => {
            if (!isMount) {
              updateFieldDetail('disabled', values[0] === 'YES')
            }
          }}
        />
      </>
    )
  }

  let textCompTypeInputs = null
  if (translatedCompType === 'text') {
    textCompTypeInputs = (
      <>
        <AutoPopulateFields
          fieldName={fieldName}
          autoPopulate={autoPopulate || {}}
          shouldAutoPopulate={shouldAutoPopulate}
          dynamicFieldValues={true}
          fieldConfig={fieldConfig}
          onShouldAutoPopulateChange={(values: string[], isMount: boolean) => {
            if (!isMount) {
              setShouldAutoPopulate(values[0] === 'YES')
            }
          }}
          onFieldValuePairChange={(item: string, field?: string, value?: string, previousField?: string) =>
            setAutoPopulateFieldValue(item, field, value, previousField)
          }
          onFieldValuesChange={setAutoPopulateByValues}
          onFieldValueRemove={removeAutoPopulateByFieldValue}
        />
        <CheckboxTabLabelled
          style={styles.sectionInput}
          itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
          label={'MULTILINE?'}
          values={multiLine ? ['yes'] : ['no']}
          selectorItems={['no', 'yes']}
          selectionHandler={(values: string[], isMount) => {
            if (!isMount) {
              updateFieldDetail('multiLine', values[0] === 'YES')
            }
          }}
        />
        <CheckboxTabLabelled
          style={styles.sectionInput}
          itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
          label={'SUGGESTIONS ENABLED?'}
          values={suggestionsEnabled ? ['yes'] : ['no']}
          selectorItems={['no', 'yes']}
          selectionHandler={(values: string[], isMount) => {
            if (!isMount) {
              updateFieldDetail('suggestionsEnabled', values[0] === 'YES')
            }
          }}
        />
        <CheckboxTabLabelled
          style={styles.sectionInput}
          itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
          label={'SHOW AS DISABLED?'}
          values={disabled ? ['yes'] : ['no']}
          selectorItems={['no', 'yes']}
          selectionHandler={(values: string[], isMount) => {
            if (!isMount) {
              updateFieldDetail('disabled', values[0] === 'YES')
            }
          }}
        />
      </>
    )
  }

  let itemSelectorInputs = null
  if (translatedCompType === 'item selector') {
    itemSelectorInputs = (
      <>
        <CheckboxTabLabelled
          style={styles.sectionInput}
          itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
          label={'SINGLE SELECTION MODE?'}
          values={singleSelectionMode ? ['yes'] : ['no']}
          selectorItems={['no', 'yes']}
          selectionHandler={(values: string[], isMount) => {
            if (!isMount) {
              updateFieldDetail('singleSelectionMode', values[0] === 'YES')
            }
          }}
        />
        {/* <TwoColumnSelector
          label="PRE-SELECTED ITEMS TO BE DISPLAYED"
          values={selectorItems || []}
          onClick={openSelectorModal}
        /> */}
      </>
    )
  }

  return (
    <div style={{ ...styles.section, ...props.style }}>
      <ConfigSectionHeader>FIELD SETTINGS</ConfigSectionHeader>
      <div style={styles.sectionInput}>
        <PickerContained
          style={{ width: '100%', padding: '10px 37px 15px' }}
          label="WHAT TYPE OF COMPONENT SHOULD BE USED TO CAPTURE THE DATA?"
          items={componentTypeOptions}
          value={translatedCompType}
          onChange={(e) => handleCompTypeChange(e.target.value)}
          transformToUpperCase={true}
          disabled={thusanyoJobType}
        />
      </div>
      {conditionalSelectorCompTypeInputs}
      {itemSelectorInputs}
      {selectorOrPickerCompTypeInputs}
      {selectorCompTypeInputs}
      {dateCompTypeInputs}
      {textCompTypeInputs}
      <CheckboxTabLabelled
        style={styles.sectionInput}
        itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
        label={'IS IT COMPULSORY FOR USERS TO ENTER A VALUE?'}
        values={compulsory ? ['yes'] : ['no']}
        selectorItems={['no', 'yes']}
        selectionHandler={(values: string[], isMount) => {
          if (!isMount) {
            updateFieldDetail('compulsory', values[0] === 'YES')
          }
        }}
        disabled={compType === ''}
      />
    </div>
  )
}

const styles = {
  section: {
    width: '100%',
    margin: '4% 0',
    paddingBottom: '20px',
  },
  sectionInput: {
    margin: '40px 1.5em 0px 1.5em',
  },
}

export default DataFieldDetails
