import { useState } from 'react'
import Radium from 'radium'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { mdiAlphaQCircleOutline } from '@mdi/js'

import { ColorPalette } from '../../config/colors'
import { removeUnderscores, toUpperCaseCustom } from '../../utils'
import TextInputOutlined from '../BaseComponents/Text/TextInputOutlined'
import { SideMenuOptions } from './SideMenuOptions'
import { SideMenuTitle } from './SideMenuTitle'

dayjs.extend(relativeTime)

interface GroupMembersSideMenuProps {
  itemNames: string[]
  selectedItemName: string
  onItemSelection: (item: string) => void
}

const GroupMembersSideMenu = (props: GroupMembersSideMenuProps) => {
  const [searchString, setSearchString] = useState('')

  const searchHandler = (e: any) => {
    setSearchString(toUpperCaseCustom(e.target.value))
  }

  const getOptionStyle = (option: string) => {
    return {
      fontSize: 'smaller',
      maxWidth: '100%',
      backgroundColor: selectedItemName === option ? ColorPalette.BUTTON_ACTIVE_GREY : ColorPalette.OFF_WHITE_LIGHT,
    }
  }

  const { selectedItemName, onItemSelection } = props

  let groupingOptions = ['All', ...props.itemNames]
  if (searchString.length) {
    groupingOptions = groupingOptions.filter((flow) => {
      return flow.toUpperCase().includes(searchString)
    })
  }

  return (
    <div style={styles.container}>
      <SideMenuTitle>Group Members</SideMenuTitle>
      <TextInputOutlined
        style={styles.textStyle}
        label="GROUP SEARCH"
        placeholder="Search for group"
        value={searchString}
        textHandler={searchHandler}
        disabled={false}
      />
      <SideMenuOptions
        options={groupingOptions}
        optionLabel={(option: string) => removeUnderscores(option)}
        optionClick={onItemSelection}
        optionMdiIconPath={mdiAlphaQCircleOutline}
        optionStyle={getOptionStyle}
      />
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    width: '100%',
    overflow: 'auto',
  },
  textStyle: {
    color: ColorPalette.PRIMARY_TEXT,
    padding: '0px 12px 10px 12px',
    marginTop: 20,
    minWidth: 120,
    width: '80%',
  },
}

export default Radium(GroupMembersSideMenu)
