import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Radium from 'radium'
import Icon from '@mdi/react'
import { mdiInformationOutline } from '@mdi/js'
import { cloneDeep, isEmpty } from 'lodash'
import equal from 'deep-equal'
import Dialog from '@material-ui/core/Dialog'

import DataFlowNavigator from '../../Navigation/DataFlowNavigator'
import SavingModal from '../../Modals/LoadingModal'
import SaveFailedModal from '../../Modals/AlertModalOneButton'
import DataFieldDetails from '../DataFields/DataFieldDetails'
import Field from './DataFlowField'
import InfoCollectorModal from '../../Modals/InfoCollector'
import { ColorPalette } from '../../../config/colors'
import { toUpperCaseCustom } from '../../../utils'
import { PeopleFlowCombinedReducer } from '../../../store'
import { ActionType } from '../../../store/actions/actions'
import { ConfigManagerService } from '../../../services'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import ButtonBlue from '../../BaseComponents/Buttons/ButtonBlue'
import ButtonGrey from '../../BaseComponents/Buttons/ButtonGrey'
import { AssociationSettingsRepository } from '../../../repositories'
import { IFieldConfigItem, ISectionConfigItem } from '../../../interfaces'

interface IDataFlowDetailsProps {
  selectedFlow?: string
  dataFlow?: Record<string, IFieldConfigItem[]>
  sectionConfig?: ISectionConfigItem[]
  selectedSection?: string
  idPassport: string
  password: string
  selectedAssociation: string
  associationRepo: AssociationSettingsRepository
  updateState: (obj: any) => void
  onSectionClick: (sectionName: string) => void
  onSectionEditClick: (sectionName: string) => void
  onFieldRemoveClick: (fieldKey: string, sectionName: string) => void
  openSelectorModal: (selectorIdentifier: string, selectorItems: string[]) => void
  onOrderChange: (dataFlowItems: any[]) => void
  remount?: () => void
}

const DataFlowDetails = (props: IDataFlowDetailsProps) => {
  const [selectedSection, setSelectedSection] = useState('')
  const [fieldOverridesModalOpen, setFieldOverridesModalOpen] = useState(false)
  const [selectorModalOpen, setSelectorModalOpen] = useState(false)
  const [savingModalOpen, setSavingModalOpen] = useState(false)
  const [saveFailedModalOpen, setSaveFailedModalOpen] = useState(false)
  const [fieldOverridesItem, setFieldOverridesItem] = useState<IFieldConfigItem>()
  const [draggableFieldItems, setDraggableFieldItems] = useState<IFieldConfigItem[]>([])

  useEffect(() => {
    if (!props.selectedSection && props.sectionConfig && props.sectionConfig.length > 0) {
      const sectionName = props.sectionConfig[0].sectionName
      setSelectedSection(sectionName)
      props.onSectionClick(sectionName)
    }
  }, [])

  useEffect(() => {
    if (props.selectedSection) {
      setSelectedSection(props.selectedSection)
    }
  }, [props.selectedSection])

  useEffect(() => {
    if (props.dataFlow && selectedSection) {
      setDraggableFieldItems(props.dataFlow[selectedSection] || [])
    }
  }, [props.dataFlow, selectedSection])

  const onDragEndReorder = ({ draggableId, source, destination }: any) => {
    if (!destination) {
      return
    }
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return
    }
    //@ts-ignore
    const newDraggableFieldItems = [...draggableFieldItems]
    const [movedItem] = newDraggableFieldItems.splice(source.index, 1)
    // @ts-ignore
    newDraggableFieldItems.splice(destination.index, 0, movedItem)
    // @ts-ignore
    setDraggableFieldItems(newDraggableFieldItems)
    props.onOrderChange(newDraggableFieldItems)
  }

  const toggleFieldOverrideModal = (fieldOverridesItem?: IFieldConfigItem) => {
    setFieldOverridesItem(fieldOverridesItem)
    setFieldOverridesModalOpen(!fieldOverridesModalOpen)
  }
  const toggleSelectorModal = () => setSelectorModalOpen((selectorModalOpen) => !selectorModalOpen)
  const toggleSavingModal = () => setSavingModalOpen((savingModalOpen) => !savingModalOpen)
  const toggleSaveFailedModal = () => setSaveFailedModalOpen((saveFailedModalOpen) => !saveFailedModalOpen)

  const saveChanges = async () => {
    const { idPassport, password, dataFlow, selectedFlow, associationRepo, updateState } = props
    toggleFieldOverrideModal()
    if (selectedFlow && dataFlow && fieldOverridesItem && fieldOverridesItem.key) {
      toggleSavingModal()
      const fieldIndex = dataFlow[selectedSection].findIndex((fieldObj: any) => fieldObj.key === fieldOverridesItem.key)
      const sectionFields = [...dataFlow[selectedSection]]
      // insert the new field at the same index of the old corresponding field
      sectionFields.splice(fieldIndex, 1, fieldOverridesItem)

      let changes = [
        {
          editedBy: idPassport,
          updatePath: ['dataFlow', selectedSection],
          updatedData: sectionFields,
          updatedMs: +new Date(),
        },
      ]
      const response = await ConfigManagerService.updateConfig(
        selectedAssociation,
        'dataFlow',
        changes,
        { username: idPassport, password },
        selectedFlow,
      )

      if (response.result === 'success') {
        associationRepo.setDataFlowConfig(selectedAssociation, selectedFlow, response.updatedConfig)
        if (props.remount) {
          props.remount()
        }
      } else {
        toggleSaveFailedModal()
      }
      toggleSavingModal()
    }
  }

  const handleFieldSelection = (selectorItems: string[]) => {
    if (fieldOverridesItem) {
      const currentFieldOverridesItem = fieldOverridesItem
      currentFieldOverridesItem.selectorItems = selectorItems
      setFieldOverridesItem(currentFieldOverridesItem)
    }
    toggleSelectorModal()
  }

  const {
    sectionConfig,
    selectedAssociation,
    associationRepo,
    onSectionEditClick,
    onSectionClick,
    onFieldRemoveClick,
  } = props

  const fieldConfig = associationRepo.getFieldConfig(selectedAssociation)
  const availableDataFields = Object.keys(fieldConfig)

  const { key, ...fieldOverrideItemParameters } = fieldOverridesItem || {}

  let sectionNavigator = null
  if (sectionConfig && sectionConfig.length > 0) {
    sectionNavigator = (
      <DataFlowNavigator
        sectionConfig={sectionConfig}
        currentSectionName={selectedSection}
        onSectionClick={(sectionName: string) => {
          setSelectedSection(sectionName)
          if (onSectionClick) {
            onSectionClick(sectionName)
          }
        }}
        editable={true}
        onSectionEditClick={onSectionEditClick}
        scrollMenuStyle={{
          width: 'calc(100% - max(2em, 2%))',
          overflowX: 'hidden',
          backgroundColor: ColorPalette.OFF_WHITE,
        }}
        scrollWrapperStyle={{ width: 'unset' }}
      />
    )
  }

  let selectSectionInstruction = null
  if (!selectedSection) {
    selectSectionInstruction = (
      <div style={styles.instruction}>
        <Icon
          path={mdiInformationOutline}
          size={1}
          color={ColorPalette.PRIMARY_BLUE}
          style={{ marginRight: '.75em' }}
        />
        Select a section to manage
      </div>
    )
  }

  let fields = null
  if (selectedSection && props.dataFlow && props.dataFlow.hasOwnProperty(selectedSection)) {
    if (props.dataFlow[selectedSection].length === 0) {
      fields = (
        <div style={styles.instruction}>
          <Icon
            path={mdiInformationOutline}
            size={1}
            color={ColorPalette.PRIMARY_BLUE}
            style={{ marginRight: '.75em' }}
          />
          {`Add a field to this section`}
        </div>
      )
    } else {
      fields = (
        <DragDropContext onDragEnd={onDragEndReorder}>
          <Droppable droppableId="dataFieldsOrder">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {draggableFieldItems.map((item: IFieldConfigItem, index: number) => {
                  const { key: fieldName, ...otherProperties } = item
                  const fieldOverridesItemWithoutKey: IFieldConfigItem | undefined = isEmpty(otherProperties)
                    ? undefined
                    : item
                  const otherFieldOptions = availableDataFields
                    .map((availableFieldName) => fieldConfig[availableFieldName].label)
                    .sort()

                  if (!fieldConfig.hasOwnProperty(fieldName)) {
                    return (
                      <Field
                        key={`field_${fieldName}`}
                        allFieldOptions={[fieldName]}
                        showMissingFieldWarning={true}
                        label={fieldName}
                        compType={'!'}
                        disabled={true}
                        fieldSelectionHandler={(text) => null}
                        onLeftButtonClick={() => onFieldRemoveClick(fieldName, selectedSection)}
                      />
                    )
                  }

                  return (
                    <Draggable key={`field_${fieldName}`} draggableId={`field_${fieldName}`} index={index}>
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}>
                            <Field
                              key={`field_${fieldName}`}
                              allFieldOptions={[fieldConfig[fieldName].label, ...otherFieldOptions]}
                              showMissingFieldWarning={false}
                              label={fieldConfig[fieldName].label}
                              number={index + 1}
                              compType={fieldConfig[fieldName].compType}
                              disabled={true}
                              dateFormat={fieldConfig[fieldName].dateFormat}
                              fieldSelectionHandler={(text) => null}
                              onLeftButtonClick={() => onFieldRemoveClick(fieldName, selectedSection)}
                              fieldOverridesItem={fieldOverridesItemWithoutKey}
                              onFieldOverrideClick={(fieldOverridesItem?: IFieldConfigItem) => {
                                const mergedFieldConfigItem = fieldName
                                  ? fieldOverridesItem
                                    ? {
                                        ...fieldConfig[fieldName],
                                        ...fieldOverridesItem,
                                      }
                                    : { ...fieldConfig[fieldName] }
                                  : undefined
                                toggleFieldOverrideModal(mergedFieldConfigItem)
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )
    }
  }

  let fieldOverride = null
  if (fieldOverridesItem) {
    fieldOverride = (
      <div style={{ padding: '2em 4em 4em 4em' }}>
        <DataFieldDetails
          //@ts-ignore (to overcome diffs between IFieldConfigItem and IDataFieldDetails)
          fieldParameters={{ key, ...cloneDeep(fieldOverrideItemParameters) }}
          fieldConfig={fieldConfig}
          updateFieldDetail={(detailName, newValue) => {
            const currentFieldOverridesItem = { ...fieldOverridesItem }
            // @ts-ignore (to overcome diffs between IFieldConfigItem and IDataFieldDetails)
            currentFieldOverridesItem[detailName] = newValue
            setFieldOverridesItem(currentFieldOverridesItem)
          }}
          existingSelectorTypeFields={[]}
          openSelectorModal={toggleSelectorModal}
          openConditionalSelectorModal={() => null}
          style={{ marginTop: '2%', textAlign: 'left' }}
          key={`dataFieldDetails_${fieldOverridesItem.key}`}
        />
        <div style={{ marginTop: '1.5em', display: 'flex', gap: '1.5em' }}>
          <ButtonGrey onClick={() => toggleFieldOverrideModal()}>Cancel</ButtonGrey>
          <ButtonBlue onClick={saveChanges} disabled={key ? equal(fieldConfig[key], fieldOverridesItem) : true}>
            Save
          </ButtonBlue>
        </div>
      </div>
    )
  }

  return (
    <>
      {sectionNavigator}
      <div style={styles.section}>
        {selectSectionInstruction}
        {fields}
      </div>
      {/* // TODO: use /components/Modals/Modal.tsx after web factor lands */}
      <Dialog
        open={fieldOverridesModalOpen}
        disableEnforceFocus={true} // necessary to allow keyboard inputn on the group name modal to work [MUI does not allow keyboard input on modals (via Portal) over Dialog]
        onBackdropClick={() => toggleFieldOverrideModal()}
        BackdropProps={{ invisible: true }}
        PaperProps={{ style: { borderRadius: 8 } }}
        style={{ backgroundColor: ColorPalette.MODAL_BACKGROUND_OVERLAY }}>
        <>{fieldOverride}</>
      </Dialog>
      <SavingModal open={savingModalOpen}>Saving changes...</SavingModal>
      <InfoCollectorModal
        open={selectorModalOpen}
        defaultItems={fieldOverridesItem?.selectorItems || []}
        header="SELECTOR OPTIONS"
        subHeader="Field selector options"
        warningMessage="Add at least one item"
        validateInput={() => true}
        transformInput={toUpperCaseCustom}
        placeholder="Enter selector option"
        successLabel="Update"
        minimumItems={1}
        dismiss={toggleSelectorModal}
        onSuccess={handleFieldSelection}
        onReject={toggleSelectorModal}
      />
      <SaveFailedModal
        open={saveFailedModalOpen}
        header="Failed to save changes"
        body={
          <div>
            Changes won't be permanently applied. Please try again.
            <p>If problem persists, please contact customer support.</p>
          </div>
        }
        buttonLabel={'Ok'}
        onClick={toggleSaveFailedModal}
      />
    </>
  )
}

const styles = {
  section: {
    width: '91%',
    margin: '3% auto',
    paddingBottom: '20px',
  },
  sectionInput: {
    width: '100%',
    maxWidth: '1000px',
    marginTop: 40,
  },
  button: {
    fontWeight: '550',
    fontSize: '0.8rem',
    backgroundColor: ColorPalette.CARD_WHITE,
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    paddingLeft: 30,
    marginTop: 0,
    width: '170px',
    ':hover': {
      backgroundColor: 'inherit',
    },
    ':active': {
      backgroundColor: 'inherit',
    },
    cursor: 'pointer',
  },
  buttonIcon: {
    width: '1.3rem',
    height: '1.3rem',
    marginRight: 15,
  },
  addFieldContainer: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center' as 'center',
    paddingRight: 10,
    cursor: 'pointer',
    minHeight: 60,
    border: '0.5px solid lightgrey',
    borderRadius: 15,
    backgroundColor: ColorPalette.CARD_WHITE,
    boxShadow: `0px 2px 2px ${ColorPalette.MEDIUM_GREY}`,
    overflow: 'hidden',
    color: ColorPalette.SECONDARY_TEXT,
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
      boxShadow: `0px 2px 2px ${ColorPalette.PRIMARY_BLUE}`,
    },
  },
  fieldType: {
    width: 105,
    padding: '0.75em 1.25em',
    // marginRight: 25,
    backgroundColor: ColorPalette.MEDIUM_GREY,
    color: ColorPalette.BUTTON_WHITE,
    ':hover': {
      backgroundColor: ColorPalette.PRIMARY_BLUE,
    },
  },
  instruction: {
    paddingTop: '21%',
    fontSize: '0.85rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: ColorPalette.SECONDARY_TEXT,
    width: '100%',
  },
}

const mapStateToProps = (state: PeopleFlowCombinedReducer) => {
  return {
    idPassport: state.sessionManager.idPassport,
    password: state.sessionManager.password,
    associationRepo: state.sessionManager.associationRepo as AssociationSettingsRepository,
    selectedAssociation: state.sessionManager.selectedAssociation,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateState: (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Radium(DataFlowDetails))
