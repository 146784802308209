import React, { Component, createRef } from 'react'
import Radium from 'radium'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { isEmpty } from 'lodash'
import { mdiInformationOutline, mdiContentDuplicate, mdiPlusBox } from '@mdi/js'
import Icon from '@mdi/react'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { pick } from 'lodash'

import NavigationBar from '../../components/Navigation/NavigationBar'
import SectionHeaderPrimary from '../../components/Headings/SectionHeaderPrimary'
import SideMenu from '../../components/Navigation/SideMenu'
import InfoCollectorModal from '../../components/Modals/InfoCollector'
import SaveFailedModal from '../../components/Modals/AlertModalOneButton'
import LoadingModal from '../../components/Modals/LoadingModal'
import { getDivisionJobTypeJobSubType, toUpperCaseCustom } from '../../utils'
import { ActionType } from '../../store/actions/actions'
import { PeopleFlowCombinedReducer } from '../../store'
import DisciplinaryOffencesSideMenu from '../../components/SideMenus/DisciplinaryOffencesSideMenu'
import DisciplinaryActionOrder from '../../components/Configurator/Discipline/DisciplinaryActionOrder'
import LabelCollector from '../../components/Modals/LabelCollector'
import { ColorPalette } from '../../config/colors'
import { SessionService, ConfigManagerService } from '../../services'
import { Toolbar } from '../../components/GeneralUI/Toolbar'
import { AssociationSettingsRepository } from '../../repositories'
import main from 'sha1'

dayjs.extend(relativeTime)

interface IDisciplineConfigProps extends RouteComponentProps {
  selectedAssociation: string
  idPassport: string
  password: string
  associationRepo: AssociationSettingsRepository
  updateState: (obj: any) => void
}

interface IDisciplineConfigState {
  savingModalOpen: boolean
  saveFailedModalOpen: boolean
  warning?: string
  selectedDivision: string
  selectedJobType: string
  selectedJobSubType: string
  selectedOffence: string
  selectorModalOpen: boolean
  selectorItems: string[]
  selectorItemsDisplay: string
  renderTimestamp: number
  key: string
  [index: string]: any
}

class DisciplineConfig extends Component<IDisciplineConfigProps, IDisciplineConfigState> {
  private sectionHeaderRef: React.RefObject<any>
  private sectionsRef: React.RefObject<any>

  constructor(props: IDisciplineConfigProps) {
    super(props)
    this.sectionHeaderRef = createRef()
    this.sectionsRef = createRef()
  }

  initialModalState = {
    duplicateModalOpen: false,
    savingModalOpen: false,
    selectorModalOpen: false,
    saveFailedModalOpen: false,
  }

  state: IDisciplineConfigState = {
    ...this.initialModalState,
    selectedDivision: '',
    selectedJobType:
      // @ts-ignore
      this.props.match?.params?.jobType && this.props.match?.params?.jobType !== 'ALL JOB POSITIONS'
        ? // @ts-ignore
          this.props.match.params.jobType
        : '',
    selectedJobSubType: '',
    selectedOffence: '',
    selectorIdentifier: '',
    selectorItems: [],
    selectorItemsDisplay: '',
    renderTimestamp: 0,
    key: '',
  }

  safeToOpenInfoCollector = true

  openSelectorModal = () => this.setState({ selectorModalOpen: true })
  closeSelectorModal = () => this.setState({ selectorModalOpen: false })

  toggleSavingModal = () => this.setState((prevState) => ({ savingModalOpen: !prevState.savingModalOpen }))
  toggleSaveFailedModal = () => this.setState((prevState) => ({ saveFailedModalOpen: !prevState.saveFailedModalOpen }))
  toggleDuplicateModal = () => this.setState((prevState) => ({ duplicateModalOpen: !prevState.duplicateModalOpen }))

  saveActionExpiryChanges = async (onSuccess?: () => void) => {
    this.toggleSavingModal()

    const { selectedOffence, selectedDivision, selectedJobType, selectedJobSubType } = this.state
    const { idPassport, password, selectedAssociation, associationRepo } = this.props

    // const disciplineConfig = associationRepo.getDisciplinaryConfig(selectedAssociation);
    const processConfig = associationRepo.getProcessConfig(selectedAssociation)
    const offenceConfig = processConfig.discipline.offenceConfig

    // TODO: possibly add consts for division, jobType, jobSubType
    const { divisionNode, jobTypeNode, jobSubTypeNode } = getDivisionJobTypeJobSubType(
      offenceConfig[selectedOffence],
      selectedDivision,
      selectedJobType,
      selectedJobSubType,
      true,
    )
    const actionExpiry =
      offenceConfig && selectedOffence
        ? offenceConfig[selectedOffence][divisionNode][jobTypeNode][jobSubTypeNode].actionExpiry
        : {}
    const actionFlow =
      offenceConfig && selectedOffence
        ? offenceConfig[selectedOffence][divisionNode][jobTypeNode][jobSubTypeNode].actionFlow
        : {}

    const changes = [
      {
        editedBy: idPassport,
        updatePath: [
          'discipline',
          'offenceConfig',
          this.state.selectedOffence,
          divisionNode,
          jobTypeNode,
          jobSubTypeNode,
        ],
        updatedData: { actionExpiry, actionFlow },
        updatedMs: +new Date(),
      },
    ]
    const response = await ConfigManagerService.updateConfig(selectedAssociation, 'processConfig', changes, {
      username: idPassport,
      password,
    })

    if (response.result === 'success') {
      if (onSuccess) {
        onSuccess()
      }
    } else {
      console.error('ERROR', response)
      this.toggleSaveFailedModal()
    }

    this.toggleSavingModal()
  }

  saveActionFlowChanges = async (onSuccess: () => void) => {
    this.toggleSavingModal()

    const { selectedJobType, selectedOffence } = this.state
    const { idPassport, password, selectedAssociation, associationRepo } = this.props

    const processConfig = associationRepo.getProcessConfig(selectedAssociation)
    const offenceConfig = processConfig.discipline.offenceConfig
    const jobType = selectedJobType || 'allJobTypes'
    const actionFlow =
      offenceConfig && selectedOffence
        ? offenceConfig[selectedOffence].allDivisions[jobType].allJobSubTypes.actionFlow
        : {}

    const changes = [
      {
        editedBy: idPassport,
        updatePath: [
          'discipline',
          'offenceConfig',
          this.state.selectedOffence,
          'allDivisions',
          jobType,
          'allJobSubTypes',
          'actionFlow',
        ],
        updatedData: actionFlow,
        updatedMs: +new Date(),
      },
    ]
    const response = await ConfigManagerService.updateConfig(selectedAssociation, 'processConfig', changes, {
      username: idPassport,
      password,
    })

    if (response.result === 'success') {
      onSuccess()
    } else {
      console.error('ERROR')
      this.toggleSaveFailedModal()
    }

    this.toggleSavingModal()
  }

  saveNewDuplicateOffence = async (newOffenceName: string, onSaved: () => void) => {
    const { selectedAssociation, idPassport, password, associationRepo } = this.props
    const { selectedJobType } = this.state

    const disciplineConfig: any = associationRepo.getDisciplinaryConfig(selectedAssociation)
    const currentSelectedOffenceData = disciplineConfig.offenceConfig[this.state.selectedOffence]
    // TODO: deal with general "" selectedJobType
    if (currentSelectedOffenceData.allDivisions.hasOwnProperty(selectedJobType)) {
      currentSelectedOffenceData.allDivisions = pick(currentSelectedOffenceData.allDivisions, [selectedJobType])
    } else {
      currentSelectedOffenceData.allDivisions[selectedJobType] = pick(
        currentSelectedOffenceData.allDivisions.allJobTypes,
        'allJobSubTypes',
      )
    }
    // add new offence to local config with settings of current selected offence
    disciplineConfig.offenceConfig[newOffenceName] = { ...currentSelectedOffenceData }

    const changes = [
      {
        editedBy: idPassport,
        updatePath: ['discipline', 'offenceConfig', newOffenceName],
        updatedData: currentSelectedOffenceData,
        updatedMs: +new Date(),
      },
    ]
    const response = await ConfigManagerService.updateConfig(selectedAssociation, 'processConfig', changes, {
      username: idPassport,
      password,
    })
    if (response.result === 'success') {
      onSaved()
    }
  }

  saveNewOffence = async (onSaved: () => void) => {
    const { selectedAssociation, idPassport, password } = this.props
    const changes = [
      {
        editedBy: idPassport,
        updatePath: ['discipline', 'offenceConfig', this.state.selectedOffence],
        updatedData: {
          allDivisions: { allJobTypes: { allJobSubTypes: { actionExpiry: {}, actionFlow: [] } } },
        },
        updatedMs: +new Date(),
      },
    ]
    const response = await ConfigManagerService.updateConfig(selectedAssociation, 'processConfig', changes, {
      username: idPassport,
      password,
    })
    if (response.result === 'success') {
      onSaved()
    }
  }

  copySettingsAndSaveNewOffence = (newFieldName: string) => {
    const key = newFieldName.split(' ').join('_').toUpperCase()
    this.handleNewOffenceSave(key, true)
    this.toggleDuplicateModal()
  }

  handleNewOffenceSave = (newOffenceName: string, duplicateCurrentOffenceSettings?: boolean) => {
    const { selectedAssociation, associationRepo, updateState } = this.props

    const { selectedDivision, selectedJobType, selectedJobSubType } = this.state

    if (duplicateCurrentOffenceSettings) {
      this.saveNewDuplicateOffence(newOffenceName, () => {
        // on successful save to db, update store state
        updateState({ associationRepo })
        this.setState({ selectedOffence: newOffenceName })
      })
    } else {
      associationRepo.addOffenceConfigItem(
        selectedAssociation,
        selectedDivision,
        selectedJobType,
        selectedJobSubType,
        newOffenceName,
      )

      this.setState({ selectedOffence: newOffenceName }, () => {
        this.saveNewOffence(() => {
          this.saveActionExpiryChanges()
          this.saveActionFlowChanges(() => {
            // on successful save to db, update store state
            updateState({ associationRepo })
          })
        })
      })
    }
  }

  handleActionExpiryChange = (action: string, expiryInMilliseconds: number) => {
    const { selectedAssociation, associationRepo, updateState } = this.props

    const { selectedDivision, selectedJobType, selectedJobSubType, selectedOffence } = this.state

    associationRepo.setOffenceConfigActionExpiry(
      selectedAssociation,
      selectedDivision,
      selectedJobType,
      selectedJobSubType,
      selectedOffence,
      action,
      expiryInMilliseconds,
    )
    this.saveActionExpiryChanges(() => {
      // on successful save to db, update store state
      updateState({ associationRepo })
    })
  }

  handleActionFlowAddition = (actionFlowName: string) => {
    const { selectedAssociation, associationRepo, updateState } = this.props

    const { selectedDivision, selectedJobType, selectedJobSubType, selectedOffence } = this.state

    associationRepo.addOffenceConfigActionFlowItem(
      selectedAssociation,
      selectedDivision,
      selectedJobType,
      selectedJobSubType,
      selectedOffence,
      actionFlowName,
    )
    this.saveActionFlowChanges(() => {
      // on successful save to db, update store state
      updateState({ associationRepo })
    })
  }

  handleActionFlowRemoval = (actionFlowIndex: number) => {
    const { selectedAssociation, associationRepo, updateState } = this.props

    const { selectedDivision, selectedJobType, selectedJobSubType, selectedOffence } = this.state

    associationRepo.removeOffenceConfigActionFlowItem(
      selectedAssociation,
      selectedDivision,
      selectedJobType,
      selectedJobSubType,
      selectedOffence,
      actionFlowIndex,
    )
    this.saveActionFlowChanges(() => {
      // on successful save to db, update store state
      updateState({ associationRepo })
    })
  }

  handleActionFlowItemChange = (actionFlowItems: string[]) => {
    const { selectedAssociation, associationRepo, updateState } = this.props

    const { selectedDivision, selectedJobType, selectedJobSubType, selectedOffence } = this.state

    associationRepo.setOffenceConfigActionFlowItems(
      selectedAssociation,
      selectedDivision,
      selectedJobType,
      selectedJobSubType,
      selectedOffence,
      actionFlowItems,
    )
    this.saveActionFlowChanges(() => {
      // on successful save to db, update store state
      updateState({ associationRepo })
      this.setState({ renderTimestamp: Date.now() })
    })
  }

  render() {
    const { selectedAssociation, associationRepo, updateState } = this.props

    const {
      selectedDivision,
      selectedJobType,
      selectedJobSubType,
      selectedOffence,
      selectorItems,
      duplicateModalOpen,
      savingModalOpen,
      saveFailedModalOpen,
      renderTimestamp,
    } = this.state

    const organisationConfig = associationRepo.getOrganisationConfig(selectedAssociation)
    const processConfig = associationRepo.getProcessConfig(selectedAssociation)
    const offenceConfig = processConfig.discipline.offenceConfig
    const offenceDetails: any = offenceConfig && selectedOffence ? offenceConfig[selectedOffence] : {}

    const toolbarActionButtons =
      selectedOffence === ''
        ? []
        : [
            {
              label: 'DUPLICATE CONFIG',
              iconPath: mdiContentDuplicate,
              onClick: this.toggleDuplicateModal,
            },
          ]
    if (isEmpty(offenceDetails) && selectedOffence !== '') {
      toolbarActionButtons.unshift({
        label: 'ADD ACTIONS',
        iconPath: mdiPlusBox,
        onClick: () => this.handleNewOffenceSave(selectedOffence),
      })
    }

    let mainContent = null
    if (isEmpty(offenceDetails)) {
      mainContent = (
        <div
          style={{
            paddingTop: '25%',
            fontSize: '0.85rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: ColorPalette.SECONDARY_TEXT,
          }}>
          <Icon
            path={mdiInformationOutline}
            size={1}
            color={ColorPalette.PRIMARY_BLUE}
            style={{ marginRight: '.75em' }}
          />
          {selectedOffence
            ? 'No actions configured. Click button in toolbar above to add.'
            : 'Select an offence on the left to see and edit settings'}
        </div>
      )
    } else {
      mainContent = (
        <>
          <DisciplinaryActionOrder
            processConfig={processConfig}
            selectedOffence={selectedOffence}
            selectedDivision={selectedDivision}
            selectedJobType={selectedJobType}
            selectedJobSubType={selectedJobSubType}
            setOffenceConfigActionExpiry={this.handleActionExpiryChange}
            addOffenceConfigActionFlow={this.handleActionFlowAddition}
            removeOffenceConfigActionFlow={this.handleActionFlowRemoval}
            setOffenceConfigActionFlowItems={this.handleActionFlowItemChange}
            key={`${selectedOffence}_${renderTimestamp}_${selectedDivision}-${selectedJobType}-${selectedJobSubType}`}
          />
        </>
      )
    }

    let modals = (
      <>
        <InfoCollectorModal
          open={this.state.selectorModalOpen}
          defaultItems={selectorItems}
          header="SELECTOR OPTIONS"
          subHeader="Field selector options"
          warningMessage="Add at least one item"
          validateInput={() => true}
          transformInput={toUpperCaseCustom}
          placeholder="Enter selector option"
          successLabel="Update"
          minimumItems={1}
          dismiss={this.closeSelectorModal}
          onSuccess={(selectorItems) => {
            this.setState({ selectorItems })
            this.closeSelectorModal()
          }}
          onReject={this.closeSelectorModal}
        />
        <SaveFailedModal
          open={saveFailedModalOpen}
          header="Failed to save changes"
          body={
            <div>
              Changes won't be permanently applied. Please try again.
              <p>If problem persists, please contact customer support.</p>
            </div>
          }
          buttonLabel={'Ok'}
          onClick={this.toggleSaveFailedModal}
        />
        <LoadingModal open={savingModalOpen}>Saving changes...</LoadingModal>
      </>
    )

    return (
      <div style={styles.container}>
        <NavigationBar match={this.props.match} location={this.props.location} history={this.props.history} />
        <SectionHeaderPrimary
          ref={this.sectionHeaderRef}
          style={styles.sectionHeader}
          disabled={true}
          searchString={''}
          onClick={() => ({})}>
          Discipline
        </SectionHeaderPrimary>
        <div style={styles.contentContainer}>
          <SideMenu
            visible={true}
            menuComponents={
              <DisciplinaryOffencesSideMenu
                selectedDivision={selectedDivision}
                selectedJobType={
                  // @ts-ignore
                  this.props.match?.params.jobType === 'allJobTypes'
                    ? // @ts-ignore
                      'ALL JOB POSITIONS'
                    : // @ts-ignore
                      this.props.match.params.jobType
                }
                selectedJobSubType={selectedJobSubType}
                selectedOffence={selectedOffence}
                offenceConfig={offenceConfig}
                organisationConfig={organisationConfig}
                onFieldChange={(stateChangeObj: {}) => this.setState(stateChangeObj)}
                onOffenceClick={(offence: string) => {
                  if (offence === 'All Offences') {
                    offence = 'allOffences'
                  }
                  this.setState({ selectedOffence: offence })
                }}
                onNewOffenceSave={this.handleNewOffenceSave}
              />
            }
          />
          <div style={styles.rightSide}>
            <Toolbar actionButtons={toolbarActionButtons} navButtons={{ left: { label: 'BACK' } }} />
            <div style={styles.rightSideContent}>
              <div style={styles.jobSections} ref={this.sectionsRef}>
                {mainContent}
              </div>
            </div>
          </div>
        </div>
        {modals}
        <LabelCollector
          open={duplicateModalOpen}
          warning="Enter offence name"
          placeholder="Enter offence name"
          buttonLabel="Add"
          iconName="setting"
          dismiss={this.toggleDuplicateModal}
          submit={this.copySettingsAndSaveNewOffence}
        />
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255, 1), rgba(209,210,230, 1))',
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 1%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    paddingInline: 'max(2em, 2%)',
    width: '100%',
    overflow: 'hidden',
  },
  rightSideContent: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    display: 'flex',
    flex: 1,
    overflow: 'auto',
    backgroundColor: ColorPalette.CARD_WHITE,
  },
  button: {
    fontWeight: 'bolder',
    fontSize: '0.8rem',
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
    },
    ':active': {
      color: ColorPalette.DARK_GREY,
    },
  },
  jobSections: {
    width: '91%',
    margin: '0 auto',
  },
}

const mapStateToProps = (state: PeopleFlowCombinedReducer) => {
  return {
    idPassport: state.sessionManager.idPassport,
    password: state.sessionManager.password,
    associationRepo: state.sessionManager.associationRepo as AssociationSettingsRepository,
    selectedAssociation: state.sessionManager.selectedAssociation,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateState: (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Radium(DisciplineConfig))
