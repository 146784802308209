import { ColorPalette } from '../../config'
import { removeUnderscores, toUpperCaseCustom, toLowerCaseCustom } from '../../utils'

import { useState, useEffect, memo } from 'react'
import Icon from '@mdi/react'
import { mdiMagnify, mdiWrench } from '@mdi/js'
import ButtonBlue from '../BaseComponents/Buttons/ButtonBlue'
import ButtonGrey from '../BaseComponents/Buttons/ButtonGrey'
import ButtonGeneric from '../BaseComponents/Buttons/ButtonGeneric'
import TextInputOutlined from '../BaseComponents/Text/TextInputOutlined'
import { Dialog, DialogActions, DialogContent, DialogTitle, Radio, RadioGroup } from '@material-ui/core'
import { isEmpty } from 'lodash'

interface IProps {
  key?: string
  items: Record<string, any>
  renderBatchSize?: number
  showSectionHeadings?: boolean
  preSelectedItems: string[]
  singleSelectionMode?: boolean
  header: string
  submitText: string
  visible: boolean
  subHeader?: string
  toggleLabelMap?: { [key: string]: string }
  searchboxPlaceholder?: string
  showSearchBox: boolean
  showActionButton?: boolean
  actionButtonText?: string
  sectionIcons?: Record<string, string>
  onActionClicked?: () => void
  onDismiss: () => void
  onSubmit: (values: string[], toggleMode?: boolean) => void
}

type SelectionManager = {
  key: string
  selected: boolean
  label: string
  value: string
  section: string
}

const ItemSelector = (props: IProps) => {
  const [state, setState] = useState({
    fullSelectionManager: [{} as SelectionManager],
    selectionManager: [{} as SelectionManager],
    warning: '',
    searchText: '',
    toggleMode: true,
    singleSelectionMode: props.singleSelectionMode,
    batchNumber: 0,
  })

  const changeState = (newValues: Record<string, any>) => {
    setState((state) => ({ ...state, ...newValues }))
  }

  useEffect(() => {
    initialiseRenderList(props.preSelectedItems)
  }, [])

  const initialiseRenderList = (preSelectedItems: string[]) => {
    const items = props.items
    const fullSelectionManager = initialiseSelectionManager(items, preSelectedItems)
    changeState({ fullSelectionManager })
    loadBatch(fullSelectionManager, state.searchText, props.renderBatchSize, state.batchNumber)
  }

  const initialiseSelectionManager = (itemData: Record<string, any>, preSelectedItems: string[]) => {
    let labels: string[] = []
    let selectionManager: any[] = [] // TODO: use type instead of any
    try {
      Object.keys(itemData).forEach((section, sectionIndex) => {
        selectionManager = props.showSectionHeadings
          ? [...selectionManager, { key: `header_${section}_${sectionIndex}`, section }]
          : selectionManager

        itemData[section].forEach((label: string, labelIndex: number) => {
          if (!labels.includes(label)) {
            labels = [...labels, label]
            const selected =
              (preSelectedItems && preSelectedItems.includes(label)) ||
              (!preSelectedItems && !state.singleSelectionMode) ||
              (labelIndex === 0 && state.singleSelectionMode && !preSelectedItems?.length)
                ? true
                : false
            selectionManager = [...selectionManager, { key: `${section}_${label}_${labelIndex}`, label, selected }]
          }
        })
      })
    } catch (error) {
      // console.log("initialiseSelectionManager error: ", error);
    }
    return selectionManager
  }

  const loadBatch = (
    fullSelectionManager: any[],
    searchText: string,
    batchSize: number | undefined,
    batchNumber: number,
  ) => {
    try {
      if (searchText.length) {
        fullSelectionManager = [...fullSelectionManager].filter((item) => {
          if (item.key.includes('header')) {
            return true
          } else if (item.label) {
            if (toLowerCaseCustom(item.label).includes(toLowerCaseCustom(searchText))) {
              return true
            } else {
              return false
            }
          } else {
            return false
          }
        })
      }

      let selectionManager = []
      batchNumber += 1

      if (batchSize !== undefined) {
        selectionManager = fullSelectionManager.slice(0, batchSize * batchNumber)

        if (selectionManager.length < fullSelectionManager.length) {
          selectionManager = [...selectionManager, { key: 'button_loadMore' }]
        }
      } else {
        selectionManager = fullSelectionManager
      }
      changeState({ selectionManager, batchNumber })
    } catch (error) {
      // console.log("error: ", error);
    }
  }

  const searchHandler = (searchText: string) => {
    changeState({ searchText })
    loadBatch(state.fullSelectionManager, searchText, props.renderBatchSize, state.batchNumber - 1)
  }

  const toggleSelectionStatus = (index: number) => {
    try {
      let { selectionManager, fullSelectionManager } = state
      if (state.singleSelectionMode) {
        selectionManager = [...selectionManager].map((item) => ({ ...item, selected: false }))
        fullSelectionManager = [...fullSelectionManager].map((item) => ({ ...item, selected: false }))

        selectionManager[index].selected = true
        fullSelectionManager = [...fullSelectionManager].map((item) =>
          item.label === selectionManager[index].label ? { ...item, selected: selectionManager[index].selected } : item,
        )
      } else {
        selectionManager[index].selected = !selectionManager[index].selected
        fullSelectionManager = [...fullSelectionManager].map((item) =>
          item.label === selectionManager[index].label ? { ...item, selected: selectionManager[index].selected } : item,
        )
      }
      changeState({ fullSelectionManager, selectionManager, warning: '' })
    } catch (error) {
      // console.log("error: ", error);
    }
  }

  const handleSubmit = () => {
    let selectedItems: string[] = []
    state.selectionManager.forEach((item) =>
      !item.section && item.selected ? (selectedItems = [...selectedItems, item.label]) : null,
    )
    if (!selectedItems.length) {
      changeState({ warning: 'Select an item to continue' })
    } else {
      props.onSubmit(selectedItems, state.toggleMode)
    }
  }

  const renderItems = (item: SelectionManager, index: number) => {
    if (item.section) {
      return (
        <div style={{ ...styles.sectionHeaderContainer }}>
          <Icon
            path={mdiWrench} // TODO: use mapping with props.sectionIcons[item.section]
            color={ColorPalette.PRIMARY_BLUE}
            style={{}}
          />
          <div style={{ ...styles.text }}>{item.section}</div>
        </div>
      )
    } else if (item.key === 'button_loadMore') {
      return (
        <ButtonGeneric
          label="Load more"
          onClick={() =>
            loadBatch(state.fullSelectionManager, state.searchText, props.renderBatchSize, state.batchNumber)
          }
          style={{
            marginTop: 20,
            width: window.innerWidth * 0.43,
            alignSelf: 'center',
            height: 35,
            borderRadius: 20,
            color: ColorPalette.DARK_GREY,
          }}
        />
      )
    } else {
      return (
        <div
          key={`radio_${item.key}+${index}`}
          style={{
            display: 'flex',
            alignItems: 'center',
            color: ColorPalette.DARK_MEDIUM_GREY,
            fontWeight: 500,
          }}>
          <Radio
            value={item.value}
            checked={item.selected}
            onClick={() => toggleSelectionStatus(index)}
            style={{ color: item.selected ? undefined : ColorPalette.DARK_MEDIUM_GREY }}
          />
          <div style={{ marginLeft: '0.5em' }}>{removeUnderscores(item.label)}</div>
        </div>
      )
    }
  }

  let warning = null
  if (state.warning) {
    warning = <div style={{ ...styles.warningText }}>{state.warning}</div>
  }

  let actionButton = null
  if (props.showActionButton) {
    actionButton = (
      <ButtonGrey
        onClick={props.onActionClicked ? props.onActionClicked : () => null}
        style={{ width: '72%', marginTop: 20 }}>
        {props.actionButtonText}
      </ButtonGrey>
    )
  }

  let searchBox = null
  if (props.showSearchBox) {
    searchBox = (
      // {/* TODO: add mdiMagnify icon? */}
      <>
        <TextInputOutlined
          icon={<Icon path={mdiMagnify} color={ColorPalette.DARK_GREY} style={{ width: '1.5em' }} />}
          style={{
            color: ColorPalette.PRIMARY_TEXT,
            width: 'unset',
            height: 35,
            padding: 30,
            textAlign: 'left',
          }}
          value={state.searchText}
          placeholder={props.searchboxPlaceholder ? props.searchboxPlaceholder : 'Filter'}
          disabled={false}
          autoFocus={true}
          textHandler={(e) => searchHandler(e.target.value)}
        />
      </>
    )
  }

  let subHeader = null
  if (props.subHeader) {
    subHeader = (
      <div style={{ ...styles.subHeaderContainer }}>
        <div style={{ ...styles.subHeaderText }}>{removeUnderscores(props.subHeader)}</div>
      </div>
    )
  }

  return (
    // TODO: use /components/Modals/Modal.tsx after web factor lands
    <Dialog open={true} PaperProps={{ style: { borderRadius: 8 } }} style={{ borderRadius: '100%' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '20px 20px 30px',
        }}>
        <DialogTitle style={{ color: ColorPalette.PRIMARY_TEXT }}>
          {toUpperCaseCustom(removeUnderscores(props.header))}
        </DialogTitle>
        <DialogContent>
          {subHeader}
          {actionButton}
          {searchBox}
          {warning}
          <div
            style={{
              marginTop: 20,
              paddingLeft: 20,
              paddingRight: 30,
            }}>
            {state.selectionManager.length > 0 && !isEmpty(state.selectionManager[0])
              ? state.selectionManager.map(renderItems)
              : null}
          </div>
        </DialogContent>
        <DialogActions
          style={{
            marginTop: 40,
            gap: 10,
            padding: 10,
          }}>
          <ButtonGrey onClick={props.onDismiss} style={{ minWidth: 200 }}>
            Cancel
          </ButtonGrey>
          <ButtonBlue onClick={handleSubmit} style={{ minWidth: 200 }}>
            {props.submitText}
          </ButtonBlue>
        </DialogActions>
      </div>
    </Dialog>
  )
}

const styles = {
  modal: {
    position: 'absolute' as 'absolute',
    top: '55%',
    backgroundColor: 'white',
    width: '60%',
    borderRadius: 15,
    paddingBottom: 30,
  },
  shadow: {
    shadowOpacity: 0.2,
    shadowRadius: 4,
    shadowOffset: {
      width: 0,
      height: 5,
    },
  },
  modalContainer: {
    height: '100%',
    width: '100%',
    justifyContent: 'flex-start',
    paddingTop: '35%',
    alignItems: 'center',
    backgroundColor: ColorPalette.MODAL_BACKGROUND_OVERLAY,
  },
  sectionHeaderContainer: {
    marginTop: 30,
    marginBottom: 20,
    width: window.innerWidth * 0.42,
    borderBottomWidth: 0.8,
    borderBottomColor: ColorPalette.PRIMARY_BLUE,
    flexDirection: 'row' as 'row',
    alignItems: 'center',
    paddingBottom: 10,
  },
  warningText: {
    alignSelf: 'center',
    marginTop: 20,
    fontFamily: 'Roboto',
    fontSize: 14,
    textAlign: 'center' as 'center',
    color: ColorPalette.WARNING_RED,
  },
  subHeaderContainer: {
    alignItems: 'center',
    alignSelf: 'center',
    marginTop: 20,
    paddingBottom: 15,
    width: '75%',
    borderBottomWidth: 0.6,
    borderBottomColor: ColorPalette.PRIMARY_TEXT,
  },
  subHeaderText: {
    fontSize: 16,
    fontWeight: 'normal',
    textAlign: 'center' as 'center',
    color: ColorPalette.TERTIARY_TEXT,
  },
  text: {
    color: ColorPalette.PRIMARY_TEXT,
    textTransform: 'uppercase' as 'uppercase',
    fontFamily: 'Roboto',
    fontWeight: 'bold' as 'bold',
    fontSize: 15,
    paddingHorizontal: 15,
  },
}

export default memo(ItemSelector)
