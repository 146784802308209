import Radium from 'radium'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { mdiAlphaQCircleOutline, mdiCheckCircle, mdiBackupRestore, mdiHardHat, mdiAccountHardHat } from '@mdi/js'

import { ColorPalette } from '../../config/colors'
import { SideMenuOptions } from './SideMenuOptions'
import { removeUnderscores } from '../../utils/stringUtils'
import { SideMenuTitle } from './SideMenuTitle'

dayjs.extend(relativeTime)

export type PpeRequestsSideMenuOption = 'READY FOR ISSUE' | 'ISSUING' | 'RETURN IN PROGRESS' | 'COMPLETE'

interface PpeRequestsSideMenuProps {
  selectedStatus: PpeRequestsSideMenuOption
  onStatusSelection: (item: PpeRequestsSideMenuOption) => void
}

const PpeRequestsSideMenu = (props: PpeRequestsSideMenuProps) => {
  const getTypeIconPath = (category: string) => {
    switch (category) {
      case 'READY FOR ISSUE':
        return mdiHardHat
      case 'ISSUING':
        return mdiAccountHardHat
      case 'RETURN IN PROGRESS':
        return mdiBackupRestore
      case 'COMPLETE':
        return mdiCheckCircle
      default:
        return mdiAlphaQCircleOutline
    }
  }

  const getSideMenuOptionStyle = (category: string) => {
    return {
      fontSize: 'smaller',
      maxWidth: '100%',
      backgroundColor: selectedStatus === category ? ColorPalette.BUTTON_ACTIVE_GREY : ColorPalette.OFF_WHITE_LIGHT,
    }
  }

  const { selectedStatus, onStatusSelection } = props

  let statusCategories = ['READY FOR ISSUE', 'ISSUING', 'RETURN IN PROGRESS', 'COMPLETE']

  return (
    <div style={styles.container}>
      <SideMenuTitle>PPE Requests</SideMenuTitle>
      <div style={{ backgroundColor: ColorPalette.OFF_WHITE_LIGHT, height: '80vh', width: '100%' }}>
        <SideMenuOptions
          options={statusCategories}
          optionLabel={(queryName: string) => removeUnderscores(queryName)}
          optionClick={(selection: string) => onStatusSelection(selection as PpeRequestsSideMenuOption)}
          optionMdiIconPath={(category: string) => getTypeIconPath(category)}
          optionStyle={getSideMenuOptionStyle}
        />
      </div>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    width: '100%',
    overflow: 'auto',
  },
  textStyle: {
    color: ColorPalette.PRIMARY_TEXT,
    padding: '0px 12px 10px 12px',
    marginTop: 20,
    minWidth: 120,
    width: '80%',
  },
}

export default Radium(PpeRequestsSideMenu)
