import { Component } from 'react'
import { connect } from 'react-redux'
import Radium from 'radium'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { toUpperCaseCustom } from '../../utils'
import { mdiCogClockwise } from '@mdi/js'

import { ColorPalette } from '../../config/colors'
import { PickerHandlerEvent } from '../BaseComponents/Pickers/Picker'
import DivisionPicker from '../BaseComponents/Pickers/PickerContained'
import LabelCollector from '../Modals/LabelCollector'
import { SessionService, ConfigManagerService } from '../../services'
import TextInputOutlined from '../BaseComponents/Text/TextInputOutlined'
import { SideMenuOptions } from './SideMenuOptions'
import { PeopleFlowCombinedReducer } from '../../store'
import { SideMenuTitle } from './SideMenuTitle'

dayjs.extend(relativeTime)

export interface ConfigScreenOption {
  title: string
  icon: string
  getConfig: (selectedEmployer: string) => any
}

interface IJobPositionsSideMenuProps {
  selectedJob: string
  selectedDivision: string
  selectedAssociation: string
  userIdPassport: string
  organisationConfig: any
  password: string
  onJobClick: (jobPosition: string) => void
  onDivisionChange: (division: string) => void
}

interface IJobPositionsSideMenuState {
  addPositionModalOpen: boolean
  searchString: string
}

class JobPositionsSideMenu extends Component<IJobPositionsSideMenuProps, IJobPositionsSideMenuState> {
  state = {
    addPositionModalOpen: false,
    searchString: '',
  }

  saveNewJobType = async (newPositionName: string) => {
    const { password, selectedAssociation, selectedDivision, organisationConfig, userIdPassport } = this.props

    const positionName = newPositionName.split(' ').join('_').toUpperCase()
    const jobTypes = organisationConfig.divisionConfig.config[selectedDivision].jobTypes
    jobTypes[positionName] = {
      jobSubTypes: {},
    }

    // add new job to organisationConfig
    let changes = [
      {
        editedBy: userIdPassport,
        updatePath: ['divisionConfig', 'config', selectedDivision, 'jobTypes'],
        updatedData: jobTypes,
        updatedMs: +new Date(),
      },
    ]
    await ConfigManagerService.updateConfig(selectedAssociation, 'organisationConfig', changes, {
      username: userIdPassport,
      password,
    })

    // now add the job to processConfig.competencies.competencyConfig
    const division = selectedDivision || 'allDivisions'
    changes = [
      {
        editedBy: userIdPassport,
        updatePath: ['competenciesv2', 'competencyConfig', division, 'allJobSubTypes', positionName],
        updatedData: { requiredCompetencies: [] },
        updatedMs: +new Date(),
      },
    ]
    await ConfigManagerService.updateConfig(selectedAssociation, 'processConfig', changes, {
      username: userIdPassport,
      password,
    })

    // optimistic update or use updatedConfig return ?
    organisationConfig.divisionConfig.config[selectedDivision].jobTypes[positionName] = {
      jobSubTypes: {},
    }
    this.closeModal()
  }

  closeModal = () => this.setState({ addPositionModalOpen: false })

  searchHandler = (e: any) => {
    this.setState({ searchString: toUpperCaseCustom(e.target.value) })
  }

  render() {
    const { organisationConfig, selectedDivision, selectedJob, onJobClick, onDivisionChange } = this.props
    const divisions = Object.keys(organisationConfig.divisionConfig.config)
    const defaultJobType = 'ALL JOB POSITIONS'
    let jobTypes = selectedDivision
      ? Object.keys(organisationConfig.divisionConfig.config[selectedDivision].jobTypes).sort()
      : []

    if (this.state.searchString.length) {
      jobTypes = jobTypes.filter((job) => job.toUpperCase().includes(this.state.searchString))
    }
    jobTypes.unshift(defaultJobType)

    return (
      <div style={styles.container}>
        <SideMenuTitle>Processes per job</SideMenuTitle>
        <DivisionPicker
          label="division"
          value={selectedDivision}
          items={divisions}
          onChange={(e: PickerHandlerEvent) => onDivisionChange(e.target.value)}
          style={{
            ...styles.pickerStyle,
            color: ColorPalette.PRIMARY_TEXT,
          }}
        />
        <TextInputOutlined
          style={styles.textStyle}
          label="JOB POSITION SEARCH"
          placeholder="Search for job position"
          value={this.state.searchString}
          textHandler={this.searchHandler}
          disabled={false}
        />
        <div style={styles.dividerLine} />
        <SideMenuOptions
          addButtonLabel="Add position"
          addButtonClick={() => this.setState({ addPositionModalOpen: true })}
          options={jobTypes}
          optionLabel={(jobType: string) => jobType.replace('dataFlow___', '').toUpperCase()}
          optionClick={onJobClick}
          optionStyle={(jobType: string) => ({
            fontSize: 'smaller',
            maxWidth: '100%',
            backgroundColor: selectedJob === jobType ? ColorPalette.BUTTON_ACTIVE_GREY : ColorPalette.OFF_WHITE_LIGHT,
          })}
          optionMdiIconPath={mdiCogClockwise}
        />
        <LabelCollector
          open={this.state.addPositionModalOpen}
          warning="Enter job position name"
          placeholder="Enter job position name"
          buttonLabel="Add"
          iconName="job"
          dismiss={this.closeModal}
          submit={this.saveNewJobType}
        />
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    width: '100%',
    overflow: 'auto',
  },
  dividerLine: {
    width: '86%',
    marginTop: 30,
    borderBottom: `1px solid ${ColorPalette.LIGHT_GREY}`,
  },
  pickerStyle: {
    marginTop: 10,
    minWidth: 120,
    width: '80%',
    backgroundColor: ColorPalette.CARD_WHITE,
  },
  textStyle: {
    color: ColorPalette.PRIMARY_TEXT,
    padding: '0px 12px 10px 12px',
    marginTop: 25,
    minWidth: 120,
    width: '80%',
  },
}

const mapStateToProps = (state: PeopleFlowCombinedReducer) => {
  return {
    password: state.sessionManager.password,
  }
}

export default connect(mapStateToProps)(Radium(JobPositionsSideMenu))
