import { ColorPalette } from '../../config/colors'

import React, { Component } from 'react'
import Radium from 'radium'
import TextInputLabelled from '../BaseComponents/Text/TextInputLabelled'
import ButtonBlue from '../BaseComponents/Buttons/ButtonBlue'
import { Property } from 'csstype'
import Picker from '../BaseComponents/Pickers/Picker'

interface LabelCollectorProps {
  open: boolean
  warning: string
  placeholder: string
  cardContainer?: React.CSSProperties | undefined
  iconName: string
  pickerItems?: string[]
  buttonLabel: string
  initialValue?: string
  buttonDisabledBehaviour?: boolean
  footer?: React.ReactElement
  style?: React.CSSProperties
  submit: (label: string) => void
  dismiss: () => void
}

interface LabelCollectorState {
  open: boolean
  mounted: boolean
  label: string
  warning: string
}

class LabelCollector extends Component<LabelCollectorProps, LabelCollectorState> {
  state: LabelCollectorState = {
    open: false,
    mounted: false,
    label: this.props.initialValue || '',
    warning: '',
  }

  componentDidMount() {
    this.setState({ open: this.props.open, mounted: this.props.open })
  }

  componentDidUpdate(prevProps: LabelCollectorProps) {
    if (prevProps.open !== this.props.open) {
      let newState: Partial<LabelCollectorState> = { open: this.props.open }
      if (!this.props.open) {
        newState = { ...newState, warning: '', label: '' }
      }
      this.setState(newState as LabelCollectorState, () =>
        setTimeout(
          () => {
            this.setState({ mounted: this.props.open })
          },
          this.props.open ? 0 : 50,
        ),
      )
    }
  }

  submit = () => {
    if (!this.state.label) {
      this.setState({ warning: this.props.warning })
      return
    }

    this.setState({ label: '' })
    try {
      this.props.submit(this.state.label)
    } catch (error: any) {
      if (error.code === 'AlreadyExists') {
        this.setState({ warning: 'Already exists' })
      }
    }
  }

  render() {
    styles.screenContainer = {
      ...styles.screenContainer,
      animation: this.state.open ? 'x 0.05s ease-in' : 'x 0.1s ease-out',
      animationName: this.state.open ? fadeInBackground : fadeOutBackground,
    }

    styles.cardContainer = {
      ...styles.cardContainer,
      animation: this.state.open ? 'x 0.05s ease-in' : 'x 0.1s ease-out',
      animationName: this.state.open ? fadeInCard : fadeOutCard,
    } as React.CSSProperties

    let modalContents = null
    if (this.state.mounted) {
      let warning = null
      if (this.state.warning) {
        warning = <p style={styles.warningTextStyle}>{this.state.warning}</p>
      }

      modalContents = (
        <div style={this.props.style}>
          <button style={styles.screenContainer} onClick={this.props.dismiss} />
          <div style={{ ...styles.cardContainer, ...this.props.cardContainer }}>
            {this.props.pickerItems && this.props.pickerItems.length ? (
              <Picker
                style={styles.pickerStyle}
                value={this.state.label}
                placeholder={this.props.placeholder}
                items={this.props.pickerItems}
                onChange={(e) => this.setState({ label: e.target.value })}
              />
            ) : (
              <TextInputLabelled
                containerStyle={{ marginTop: 0 }}
                primaryStyle={{ width: '75%', height: 50 }}
                inputLabelProps={{ color: 'secondary' }}
                label={this.props.placeholder}
                value={this.state.label}
                defaultValue={this.props.initialValue}
                textHandler={(value) => this.setState({ label: value })}
                multiline={false}
                maxLength={100}
                icon={this.props.iconName}
              />
            )}
            <ButtonBlue
              style={{ marginTop: 25, width: '100%' }}
              disabled={this.props.buttonDisabledBehaviour ? this.state.label === '' : false}
              onClick={this.submit}>
              {this.props.buttonLabel}
            </ButtonBlue>
            {warning}
            {this.props.footer}
          </div>
        </div>
      )
    }
    return modalContents
  }
}

const fadeInBackground = Radium.keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
}) as Property.AnimationName

const fadeOutBackground = Radium.keyframes({
  '0%': { opacity: 1 },
  '100%': { opacity: 0 },
}) as Property.AnimationName

const fadeInCard = Radium.keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1, marginRight: window.innerHeight * 0.05 },
}) as Property.AnimationName

const fadeOutCard = Radium.keyframes({
  '0%': { marginRight: window.innerHeight * 0.05 },
  '50%': { opacity: 0 },
  '100%': { marginRight: -window.innerHeight * 0.13, opacity: 0 },
}) as Property.AnimationName

let styles: Record<string, React.CSSProperties> = {
  screenContainer: {
    zIndex: 10000,
    position: 'absolute',
    top: 0,
    left: 0,
    width: window.innerWidth,
    height: window.innerHeight,
    backgroundColor: ColorPalette.MODAL_BACKGROUND_OVERLAY,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 0,
    border: 'none',
    overflow: 'hidden',
  },
  cardContainer: {
    zIndex: 10000,
    position: 'absolute',
    top: window.innerHeight * 0.16,
    left: window.innerWidth * 0.38,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
    backgroundColor: ColorPalette.CARD_WHITE,
    width: window.innerWidth * 0.3,
    padding: 30,
    borderRadius: 8,
  },
  textStyle: {
    fontSize: '0.8rem',
    fontWeight: '300',
    marginTop: -10,
    color: ColorPalette.PRIMARY_TEXT,
  },
  warningTextStyle: {
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: '0.9rem',
    fontWeight: '300',
    marginTop: 30,
    color: ColorPalette.WARNING_RED,
  },
  pickerStyle: {
    color: ColorPalette.PRIMARY_TEXT,
    outline: 'none',
    borderBottom: 'none',
    marginInline: '1em',
  },
}

export default Radium(LabelCollector)
